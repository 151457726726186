import React, { useState } from 'react';
import "../styles/portfolio.scss";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

export default function Portfolio() {
    const [place, setPlace] = useState(0);
    

    const slide = (left) => {
        const element = document.querySelector('#image-slider-portfolio');

        let str = element.style.left;
        if(!str) str = '0%'
        console.log(str, element.style)
        let leftVal = parseInt(str.substring(0,str.length-1))
        if(left===false) leftVal -= 15
        else leftVal += 15
        if(leftVal>15) leftVal=-15;
        if(leftVal<-15) leftVal=15;
        element.style.left = `${leftVal}vw`;
        setPlace(leftVal);
    };

    return (
        <div className="portfolio" id="portfolio">
            <h5>Portfolio</h5>
            <h2>Get a Best View of Our Best Designs</h2>

            <div className="images">
                <div className="image-inner" id="image-slider-portfolio">
                    <div className="img-parent"> <img src="/assets/img/port1.png" alt="" /></div>
                    <div className="img-parent">
                        <div className="cover"></div>
                        <div className='content'>
                        <h4>Food Booking Website</h4>
                        <p>We will bring the aura of our experience and industry knowledge to help your business succeed and grow.</p>
                        <a href='/'>Learn More</a>
                        </div>
                        <img src="/assets/img/port2.png" alt="" />
                    </div>
                    <div className="img-parent"> <img src="/assets/img/port3.png" alt="" /></div>
                    <div className="img-parent"> <img src="/assets/img/port4.png" alt="" /></div>
                </div>
                <div className='indicators'>
                    <span className='indicator' style={{ width: place === 15 ? '22px' : '8px' }}></span>
                    <span className='indicator' style={{ width: place === 0 ? '22px' : '8px' }}></span>
                    <span className='indicator' style={{ width: place === -15 ? '22px' : '8px' }}></span>
                </div>
                <ArrowBackIosNewRoundedIcon className='btn-left' onClick={() => slide(true)} />
                <ArrowForwardIosRoundedIcon className='btn-right' onClick={() => slide(false)} />
            </div>
        </div>
    );
}
