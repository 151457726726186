import React from 'react'

function SvgTwitter() {
  return (
    <svg
              xmlns="http://www.w3.org/2000/svg"
              width="66"
              height="65"
              viewBox="0 0 66 65"
              fill="none"
            >
              <rect
                opacity="0.1"
                x="0.5"
                width="65"
                height="65"
                rx="20"
                fill="#6E6E6E"
              />
              <g clipPath="url(#clip0_83_553)">
                <path
                  d="M47.0494 24.5412C46.0315 24.9915 44.952 25.2872 43.8467 25.4186C45.0117 24.7218 45.8837 23.6253 46.3001 22.3332C45.2067 22.9839 44.0081 23.4399 42.7587 23.6866C41.9195 22.7887 40.8072 22.1932 39.5947 21.9927C38.3821 21.7922 37.1373 21.9979 36.0538 22.5778C34.9702 23.1578 34.1086 24.0795 33.6029 25.1996C33.0972 26.3198 32.9757 27.5756 33.2574 28.7719C31.0402 28.6608 28.8712 28.0846 26.8911 27.0808C24.9111 26.077 23.1642 24.668 21.7641 22.9452C21.2685 23.7965 21.008 24.7642 21.0094 25.7492C21.0094 27.6826 21.9934 29.3906 23.4894 30.3906C22.6041 30.3627 21.7382 30.1236 20.9641 29.6932V29.7626C20.9643 31.0502 21.4099 32.2981 22.2252 33.2946C23.0405 34.2912 24.1754 34.9752 25.4374 35.2306C24.6156 35.4533 23.7538 35.4861 22.9174 35.3266C23.2732 36.4349 23.9667 37.4042 24.9009 38.0987C25.835 38.7933 26.9629 39.1783 28.1267 39.1999C26.97 40.1083 25.6456 40.7799 24.2292 41.1761C22.8128 41.5724 21.3322 41.6856 19.8721 41.5092C22.421 43.1485 25.3882 44.0187 28.4187 44.0159C38.6761 44.0159 44.2854 35.5186 44.2854 28.1492C44.2854 27.9092 44.2787 27.6666 44.2681 27.4292C45.3599 26.6401 46.3022 25.6626 47.0507 24.5426L47.0494 24.5412Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_83_553">
                  <rect
                    width="32"
                    height="32"
                    fill="white"
                    transform="translate(17.5 17)"
                  />
                </clipPath>
              </defs>
            </svg>
  )
}

export default SvgTwitter
