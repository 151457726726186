import React from 'react'

function SvgMailSmile() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 38.2778C29.8184 38.2778 37.7778 30.3184 37.7778 20.5C37.7778 10.6816 29.8184 2.72222 20 2.72222C10.1816 2.72222 2.22222 10.6816 2.22222 20.5C2.22222 30.3184 10.1816 38.2778 20 38.2778ZM20 40.5C31.0457 40.5 40 31.5457 40 20.5C40 9.45433 31.0457 0.5 20 0.5C8.95433 0.5 0 9.45433 0 20.5C0 31.5457 8.95433 40.5 20 40.5Z"
      fill="#0F0F0F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8832 24.9444C13.6111 29.2021 16.7936 31.6111 20 31.6111C23.2065 31.6111 26.3889 29.2021 28.1168 24.9444H11.8832ZM20 33.8333C15.5132 33.8333 11.6242 30.4467 9.71325 25.4999C9.69491 25.4524 9.67669 25.4047 9.65869 25.3569C9.16058 24.0333 10.222 22.7222 11.6364 22.7222H28.3637C29.778 22.7222 30.8395 24.0333 30.3414 25.3569C30.3234 25.4047 30.3051 25.4524 30.2868 25.4999C28.3758 30.4467 24.4868 33.8333 20 33.8333Z"
      fill="#0F0F0F"
    />
    <path
      d="M17.0026 18.769C17.147 18.3715 17.1038 17.886 16.9841 17.4432C16.8593 16.9814 16.6296 16.4839 16.3059 16.0218C15.6608 15.1004 14.5909 14.2534 13.1456 14.1926C11.6491 14.1299 10.5309 15.0795 9.842 16.0875C9.49256 16.599 9.23278 17.1522 9.06989 17.6612C8.91134 18.1563 8.82723 18.6632 8.88267 19.0683C8.90834 19.2563 9.02823 19.4182 9.20045 19.4975C9.37278 19.577 9.57367 19.563 9.73323 19.4605C10.177 19.1756 10.6433 18.7858 11.0742 18.4255C11.2704 18.2614 11.4594 18.1035 11.6354 17.9645C12.2602 17.4711 12.7463 17.1899 13.1494 17.1702C13.5323 17.1514 13.9749 17.371 14.5323 17.788C14.7317 17.9372 14.9251 18.095 15.1254 18.2585C15.1969 18.3168 15.2691 18.3758 15.3428 18.4352C15.6137 18.654 15.9021 18.8788 16.1929 19.0545C16.3351 19.1406 16.5084 19.1584 16.6652 19.1032C16.822 19.0479 16.9458 18.9252 17.0026 18.769ZM22.9036 18.769C22.7591 18.3715 22.8023 17.886 22.922 17.4432C23.0468 16.9814 23.2766 16.4839 23.6002 16.0218C24.2453 15.1004 25.3153 14.2534 26.7606 14.1926C28.257 14.1299 29.3752 15.0795 30.0641 16.0875C30.4137 16.599 30.6733 17.1522 30.8363 17.6612C30.9949 18.1563 31.0789 18.6632 31.0235 19.0683C30.9978 19.2563 30.8779 19.4182 30.7057 19.4975C30.5335 19.577 30.3326 19.563 30.1729 19.4605C29.7292 19.1756 29.2628 18.7858 28.8319 18.4255C28.6357 18.2614 28.4467 18.1035 28.2707 17.9645C27.6459 17.4711 27.1598 17.1899 26.7567 17.1702C26.3739 17.1514 25.9312 17.371 25.3739 17.788C25.1746 17.9372 24.9811 18.095 24.7807 18.2585C24.7093 18.3168 24.637 18.3758 24.5633 18.4352C24.2924 18.654 24.004 18.8788 23.7133 19.0545C23.571 19.1406 23.3978 19.1584 23.241 19.1032C23.0842 19.0479 22.9604 18.9252 22.9036 18.769Z"
      fill="#0F0F0F"
    />
  </svg>

  )
}

export default SvgMailSmile