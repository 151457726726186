import React from 'react'

function SvgFaceBook() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
  >
    <rect
      opacity="0.1"
      x="0.5"
      width="65"
      height="65"
      rx="20"
      fill="#6E6E6E"
    />
    <g clipPath="url(#clip0_83_541)">
      <path
        d="M36.1666 35.0001H39.4999L40.8333 29.6667H36.1666V27.0001C36.1666 25.6267 36.1666 24.3334 38.8333 24.3334H40.8333V19.8534C40.3986 19.7961 38.7573 19.6667 37.0239 19.6667C33.4039 19.6667 30.8333 21.8761 30.8333 25.9334V29.6667H26.8333V35.0001H30.8333V46.3334H36.1666V35.0001Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_83_541">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(17.5 17)"
        />
      </clipPath>
    </defs>
  </svg>
  )
}

export default SvgFaceBook
