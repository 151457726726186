import React from 'react'

function SvgMainleft() {
  return (
    <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                >
                  <path
                    d="M11.2759 8.64276C11.0924 8.82056 11.0081 9.07768 11.0499 9.32985L11.6796 12.8148C11.7327 13.1102 11.6081 13.4091 11.3609 13.5798C11.1186 13.7569 10.7963 13.7782 10.5321 13.6365L7.39491 12.0003C7.28583 11.9422 7.1647 11.911 7.04075 11.9075H6.84879C6.7822 11.9174 6.71704 11.9386 6.65754 11.9712L3.51962 13.6153C3.3645 13.6932 3.18883 13.7208 3.0167 13.6932C2.59737 13.6138 2.31758 13.2143 2.38629 12.7929L3.0167 9.30789C3.0585 9.0536 2.9742 8.79506 2.79075 8.61443L0.232954 6.13526C0.019037 5.92772 -0.055338 5.61606 0.042412 5.33485C0.137329 5.05435 0.379579 4.84964 0.67212 4.8036L4.19254 4.29289C4.46029 4.26526 4.69545 4.10235 4.81587 3.86151L6.36712 0.681097C6.40395 0.610264 6.45141 0.545097 6.50879 0.489847L6.57254 0.440264C6.60583 0.40343 6.64408 0.372972 6.68658 0.34818L6.76379 0.319847L6.8842 0.270264H7.18241C7.44875 0.297889 7.6832 0.457264 7.80575 0.695264L9.37754 3.86151C9.49087 4.09314 9.71116 4.25393 9.96546 4.29289L13.4859 4.8036C13.7834 4.8461 14.032 5.05151 14.1305 5.33485C14.2232 5.61889 14.1432 5.93056 13.925 6.13526L11.2759 8.64276Z"
                    fill="#FEBB43"
                  />
                </svg>
  )
}

export default SvgMainleft