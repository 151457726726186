import React from 'react'

function SvgLinkdin() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
  >
    <rect
      opacity="0.1"
      x="0.5"
      width="65"
      height="65"
      rx="20"
      fill="#6E6E6E"
    />
    <g clipPath="url(#clip0_83_547)">
      <path
        d="M26.7533 23.6668C26.7529 24.374 26.4716 25.0522 25.9713 25.552C25.4709 26.0519 24.7925 26.3325 24.0853 26.3321C23.378 26.3318 22.6999 26.0505 22.2 25.5501C21.7002 25.0498 21.4196 24.3714 21.4199 23.6641C21.4203 22.9569 21.7016 22.2787 22.2019 21.7789C22.7023 21.279 23.3807 20.9984 24.0879 20.9988C24.7952 20.9991 25.4733 21.2804 25.9732 21.7808C26.473 22.2811 26.7536 22.9595 26.7533 23.6668ZM26.8333 28.3068H21.4999V45.0001H26.8333V28.3068ZM35.2599 28.3068H29.9533V45.0001H35.2066V36.2401C35.2066 31.3601 41.5666 30.9068 41.5666 36.2401V45.0001H46.8333V34.4268C46.8333 26.2001 37.4199 26.5068 35.2066 30.5468L35.2599 28.3068Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_83_547">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(17.5 17)"
        />
      </clipPath>
    </defs>
  </svg>
  )
}

export default SvgLinkdin
