import React from 'react'

const SvgStarFade = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M21.2 10.2067C21.1911 10.4187 21.0791 10.6608 20.8616 10.852C19.991 11.6167 19.12 12.3808 18.2484 13.1441C17.8415 13.5012 17.434 13.8577 17.026 14.2136C16.9677 14.2643 16.9529 14.3092 16.9708 14.3879C17.284 15.7566 17.5947 17.126 17.9028 18.4959C17.9652 18.7712 18.0266 19.047 18.094 19.3214C18.1923 19.7277 18.0668 20.1292 17.7501 20.3565C17.4017 20.6064 17.0317 20.6227 16.6634 20.4043C15.4903 19.7069 14.3187 19.0067 13.1486 18.3037C12.8754 18.1405 12.5989 17.9811 12.3303 17.8119C12.2404 17.7553 12.1752 17.7548 12.0829 17.8102C10.6599 18.6644 9.23388 19.5133 7.81259 20.3672C7.51074 20.5484 7.20244 20.6165 6.86881 20.4795C6.42739 20.2984 6.19676 19.8343 6.30646 19.352C6.61667 17.9868 6.92736 16.6217 7.23853 15.2566C7.30402 14.9698 7.36257 14.6809 7.43355 14.3962C7.4541 14.314 7.43976 14.2655 7.37739 14.2112C6.84268 13.7462 6.30933 13.2795 5.77733 12.8112C5.03192 12.1569 4.28363 11.507 3.54252 10.8479C3.23995 10.5793 3.12524 10.2363 3.24927 9.84414C3.37331 9.45195 3.65054 9.21583 4.064 9.17329C4.79675 9.09825 5.53069 9.03539 6.26416 8.96823C6.98974 8.90179 7.71532 8.83583 8.4409 8.77035C8.68276 8.7486 8.92462 8.72422 9.16672 8.70582C9.24463 8.70008 9.28622 8.66591 9.31537 8.59493C9.9726 7.05056 10.6309 5.50683 11.2904 3.96374C11.4725 3.53713 11.7978 3.29886 12.2112 3.30961C12.6247 3.32037 12.923 3.52232 13.0869 3.90208C13.5331 4.93524 13.9736 5.97079 14.4159 7.00587C14.6411 7.53165 14.8667 8.05887 15.088 8.58704C15.1219 8.6683 15.1704 8.69985 15.2553 8.70654C15.8384 8.75593 16.4214 8.80771 17.0042 8.86188C17.8528 8.939 18.7013 9.01675 19.5497 9.09514C19.8193 9.11904 20.0891 9.14294 20.358 9.17305C20.847 9.23089 21.2038 9.63885 21.2 10.2067Z" fill="#E7E7E7"/>
  </svg>
  )
}

export default SvgStarFade
