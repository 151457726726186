import React, { useState } from "react";
import "../styles/head.scss";
import { NavLink } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';

const Head = () => {
  const [navModal , setNavModal]  = useState(false)
  function handleClick(scroll) {
    if(scroll===true) {
     setTimeout(()=>{
      const element = document.getElementById('portfolio')
      element.scrollIntoView()
     }, 50)
    }
  else  window.scrollTo(0,0);
    setNavModal(false)
  }
  const links = [
    { path: "/", label: "Home" },
    { path: "/about", label: "About Us" },
    { path: "/service", label: "Services" },
    { path: "/#portfolio", label: "Portfolio" },
    { path: "/contact-us", label: "Contact Us" },
  ];
  return (
    <>
      <div className="header-parent">
        <div className="header">
          <div className="heading">SocialTeaser</div>
          <div className={`nav ${navModal === true ? "show" : ""}`}>
            {links.map((link, index) => (
              <NavLink key={index} to={link.path} className={link.label==='Portfolio'?'no-active':''} onClick={()=>handleClick(link.label==='Portfolio'?true:false)}>
                {link.label}
              </NavLink>
            ))}
          </div>
          <div className={`back-drop ${navModal === true ? "show" : ""}`} onClick={(e)=>setNavModal(false)} ></div>
          <div className="menu" onClick={(e)=>setNavModal(!navModal)}>
            <MenuIcon/>
          </div>
        </div>
        <div className="down"></div>
      </div>
    </>
  );
};

export default Head;
