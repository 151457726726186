import React, {useState} from "react";
import "../styles/form.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useForm } from "react-hook-form";
import SvgFaceBook from "../utility/SvgFaceBook";
import SvgTwitter from "../utility/SvgTwitter";
import SvgInsta from "../utility/SvgInsta";
import SvgLinkdin from "../utility/SvgLinkdin";
import SvgYoutube from "../utility/SvgYoutube";
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


const Form = () => {
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const sendDetails = (data) => {
    setIsLoading(true);
    emailjs.send("service_ofd17cj","template_mo3ur8q", {
      name: data.fullName,
      email: data.email,
      pNumber: data.phoneNumber,
      company: data.company,
      message: data.message,
    }, "TPo4QfCqucAl8gtR1")
    .then((response) => {
      console.log('Email sent successfully:', response);
      toast.success('We got you contact details, we will reach out to you soon!');
      document.getElementById("contact-form").reset();
    })
    .catch((error) => {
      console.error('Failed to send email:', error);
      toast.error('Failed to submit your contact details');
    })
    .finally(() => {
      setIsLoading(false);
    });
  };


  return (
    <>
      <div className="contact-us-form">
        <ToastContainer />
        <h5 className="head1">Contact Us</h5>
        <h2 className="head2">Don’t Hesitate Reach Out to Us </h2>
        <div className="parent">
          <div className="left-parent-contact">
            <div className="leftform">Contact Us</div>
            <div className="leftform1">
              Don’t hesitate just contact us and get your work started
            </div>
            <div className="EmailName">
              <div className="icon">
                <MailOutlineIcon />
              </div>
              <ul>
                <ul>
                  <li className="email">
                    Email: <br />
                    <span id="emailid">info@socialteaser.com</span>
                  </li>
                </ul>
              </ul>
            </div>
            <div className="EmailName">
              <div className="icon">
                <LocalPhoneIcon />
              </div>
              <ul>
                <ul>
                  <li className="email">
                    Phone Number: <br />
                    <span id="emailid">+91 88718 88296</span>
                  </li>
                </ul>
              </ul>
            </div>
            <div className="followus">
              <span>Follow us on:</span>
              <div className="socialmediaparent">
                <Link>
                  <SvgFaceBook />
                </Link>
                <Link>
                  <SvgTwitter />
                </Link>
                <Link>
                  <SvgInsta />
                </Link>
                <Link>
                  <SvgLinkdin />
                </Link>
                <Link>
                  <SvgYoutube />
                </Link>
              </div>
            </div>
            <div>

            </div>
          </div>
          <form id="contact-form" className="custom-form" onSubmit={handleSubmit(sendDetails)}>
            <div className="row">
              <div className="column">
                <label htmlFor="fullName">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  className="input1"
                  placeholder="Enter Here"
                  {...register("fullName")}
                />
              </div>
              <div className="column">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  className="input1"
                  placeholder="Enter Here"
                  {...register("email")}
                />
              </div>
              <div className="column">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="text"
                  id="phoneNumber"
                  className="input1"
                  placeholder="Enter Here"
                  {...register("phoneNumber")}
                />
              </div>
              <div className="column">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  className="input1"
                  placeholder="Enter Here"
                  {...register("company")}
                />
              </div>
            </div>
            <div className="row">
              <div className="column">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  className="input5"
                  placeholder="Enter Here"
                  {...register("message")}
                />
              </div>
            </div>
            <div className="sshh">
              <div className="hh">
                <button type="submit" className="ss" disabled={isLoading}>
                  {isLoading ? 'Sending...' : 'Send Message'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Form;
