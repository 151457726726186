import { useState } from "react";
import "../styles/Testimonial.css";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import Svgrating from "../utility/Svgrating";
import SvgStarFade from "../utility/SvgStarFade";

const Testimonial = () => {
  const [place, setPlace] = useState(0);

  const testimonials = [
    {
      rate: 4,
      text: "We will bring the aura of our experience and industry knowledge to help your business succeed and grow.",
      author: "Anish Singh",
      position: "VP at Microsoft",
    },
    {
      rate: 5,
      text: "We will bring the aura of our experience and industry knowledge to help your business succeed and grow.",
      author: "Anish Singh",
      position: "VP at Microsoft",
    },
    {
      rate: 4,
      text: "We will bring the aura of our experience and industry knowledge to help your business succeed and grow.",
      author: "Anish Singh",
      position: "VP at Microsoft",
    },
    {
      rate: 3,
      text: "We will bring the aura of our experience and industry knowledge to help your business succeed and grow.",
      author: "Anish Singh",
      position: "VP at Microsoft",
    },
    {
      rate: 4,
      text: "We will bring the aura of our experience and industry knowledge to help your business succeed and grow.",
      author: "Anish Singh",
      position: "VP at Microsoft",
    },
  ];

  const slide = (left) => {
    const element = document.querySelector("#move-rating-parent");
    let str = element.style.left;
    if (!str) str = "0%";
    let leftVal = parseInt(str.substring(0, str.length - 1));
    if (left === false) leftVal -= 15;
    else leftVal += 15;
    if (leftVal > 10) leftVal = 10;
    if (leftVal <-30) leftVal = -30;
    element.style.left = `${leftVal}%`;
    setPlace(leftVal);
  };

  return (
    <>
      <div className="testparent">
        <div className="partest">
          <div>
          <p className="testimonial">TESTIMONIALS</p>
          <p className="clients">What Our Clients Have to Say</p>
          </div>
          <div className="childbutton">
            <div className="hmm">
              <button className="schedule">Schedule a Call</button>
            </div>
            <div className="hm12">
              <button className="view">View Services</button>
            </div>
          </div>
        </div>
        <div className="ratingparent" id="move-rating-parent">
          {testimonials.map((testimonial, index) => (
            <div className="rate" key={index}>
              <div className="rating">
                {[1, 2, 3, 4, 5].map((ct, index) => (
                  <div key={index}>
                    {ct > testimonial.rate ? <SvgStarFade /> : <Svgrating />}
                  </div>
                ))}
              </div>
              <p className="text">{testimonial.text}</p>
              <p className="author">{testimonial.author}</p>
              <p className="position">{testimonial.position}</p>
            </div>
          ))}
        </div>

        <ArrowBackIosNewRoundedIcon
          className="btn-left"
          onClick={() => slide(true)}
        />
        <ArrowForwardIosRoundedIcon
          className="btn-right"
          onClick={() => slide(false)}
        />
      </div>
    </>
  );
};

export default Testimonial;
