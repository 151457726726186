import React from 'react'

function SvgYoutube() {
  return (
    <svg
              xmlns="http://www.w3.org/2000/svg"
              width="66"
              height="65"
              viewBox="0 0 66 65"
              fill="none"
            >
              <rect
                opacity="0.1"
                x="0.5"
                width="65"
                height="65"
                rx="20"
                fill="#6E6E6E"
              />
              <g clipPath="url(#clip0_83_559)">
                <path
                  d="M46.224 25.6639C46.8333 28.0399 46.8333 32.9999 46.8333 32.9999C46.8333 32.9999 46.8333 37.9599 46.224 40.3359C45.8853 41.6493 44.8946 42.6826 43.64 43.0319C41.3613 43.6666 33.5 43.6666 33.5 43.6666C33.5 43.6666 25.6426 43.6666 23.36 43.0319C22.1 42.6773 21.1106 41.6453 20.776 40.3359C20.1666 37.9599 20.1666 32.9999 20.1666 32.9999C20.1666 32.9999 20.1666 28.0399 20.776 25.6639C21.1146 24.3506 22.1053 23.3173 23.36 22.9679C25.6426 22.3333 33.5 22.3333 33.5 22.3333C33.5 22.3333 41.3613 22.3333 43.64 22.9679C44.9 23.3226 45.8893 24.3546 46.224 25.6639ZM30.8333 37.6666L38.8333 32.9999L30.8333 28.3333V37.6666Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_83_559">
                  <rect
                    width="32"
                    height="32"
                    fill="white"
                    transform="translate(17.5 17)"
                  />
                </clipPath>
              </defs>
            </svg>
  )
}

export default SvgYoutube
